import { css } from '@emotion/core'
import { screenSizeType, mqSizeMap } from '../../../constants/theme.styles'
import { FuseItemType } from './Fuse'

// ATTACHED BUTTON STYLES
export const buttonStyles = css`
  flex-grow: 1;
  :before,
  :after {
    position: absolute;
    background: inherit;
  }
`

export const buttonStacksAtStyles = (screenSize: screenSizeType) => {
  return css`
    @media ${mqSizeMap[screenSize]} {
      :before,
      :after {
        width: auto;
        height: 2px;
        left: -1px;
        right: -1px;
      }
    }
  `
}

export const buttonPositionStyles = (position: FuseItemType) => {
  if (position === 'first') {
    return css`
      :before {
        content: '';
        top: -1px;
        bottom: -1px;
        width: 2px;
        right: -2px;
      }
    `
  }
  if (position === 'attached') {
    return css`
      :before {
        content: '';
        top: -1px;
        bottom: -1px;
        width: 2px;
        left: -2px;
      }
      :after {
        content: '';
        top: -1px;
        bottom: -1px;
        width: 2px;
        right: -2px;
      }
    `
  }
  if (position === 'last') {
    return css`
      :before {
        content: '';
        top: -1px;
        bottom: -1px;
        width: 2px;
        left: -2px;
      }
    `
  }
}

export const buttonStacksAtPositionStyles = (
  position: FuseItemType,
  screenSize: screenSizeType
) => {
  if (position === 'first') {
    return css`
      :before {
        @media ${mqSizeMap[screenSize]} {
          content: '';
          top: auto;
          bottom: -2px;
        }
      }
    `
  }
  if (position === 'attached') {
    return css`
      :before {
        @media ${mqSizeMap[screenSize]} {
          content: '';
          top: auto;
          bottom: -2px;
        }
      }
      :after {
        @media ${mqSizeMap[screenSize]} {
          content: '';
          bottom: auto;
          top: -2px;
        }
      }
    `
  }
  if (position === 'last') {
    return css`
      :before {
        @media ${mqSizeMap[screenSize]} {
          content: '';
          bottom: auto;
          top: -2px;
        }
      }
    `
  }
}

import React from 'react'
import { css } from '@emotion/core'
import Icon, { IconNames } from '../Icon/Icon'
import {
  GRAY_MEDIUM,
  GRAY_MEDIUM_DARK,
  PURPLE,
  MAIN_FONT_FAMILY,
  GRAY_LIGHT
} from '../../../constants/theme.styles'
import { EmotionStyles } from '../../../types/types'
import ImpressLink from '~/components/common/Links/ImpressLink'

const inputWrapperStyles = css`
  position: relative;
  font-weight: 400;
  display: inline-flex;
  min-width: 0;
`

const inputFieldStyles = css`
  font-family: ${MAIN_FONT_FAMILY};

  /* clears the 'X' from IE */
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  /* Clear default browser styles */
  appearance: none;

  /* Clear safari contacts autocomplete icons */
  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
  }

  caret-color: ${GRAY_MEDIUM};
  &::-webkit-input-placeholder {
    font-family: ${MAIN_FONT_FAMILY};
    color: ${GRAY_MEDIUM};
  }
  &::-moz-placeholder {
    font-family: ${MAIN_FONT_FAMILY};
    color: ${GRAY_MEDIUM};
  }
  &:-ms-input-placeholder {
    font-family: ${MAIN_FONT_FAMILY};
    color: ${GRAY_MEDIUM};
  }

  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid ${GRAY_LIGHT};
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.23em;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  box-shadow: none;
  &:focus,
  &:active {
    border-color: ${PURPLE};
    caret-color: ${GRAY_MEDIUM_DARK};

    &::-webkit-input-placeholder {
      color: ${GRAY_MEDIUM_DARK};
    }
    &::-moz-placeholder {
      color: ${GRAY_MEDIUM_DARK};
    }
    &:-ms-input-placeholder {
      color: ${GRAY_MEDIUM_DARK};
    }
  }
  :read-only {
    pointer-events: none;
    opacity: 0.45;
  }
`

const disabledInputStyles = css`
  pointer-events: none;
  opacity: 0.45;
`

const disabledIconStyles = css`
  opacity: 0.45;
`

const inputErrorStyles = css`
  border-color: #e34b4b;
  background-color: #fff2f2;
  color: #e34b4b;
  &:focus,
  &:active {
    background-color: #fff !important;
    border-color: #906ff5 !important;
    color: #212121 !important;
  }
  &::-webkit-input-placeholder {
    color: #e38484;
  }

  &::-moz-placeholder {
    color: #e38484;
  }

  &:-ms-input-placeholder {
    color: #e38484 !important;
  }

  &:focus::-webkit-input-placeholder {
    color: #869399;
  }

  &:focus::-moz-placeholder {
    color: #869399;
  }

  &:focus:-ms-input-placeholder {
    color: #869399 !important;
  }
`

const fluidStyles = css`
  display: flex;
  > input {
    width: 0;
  }
`

// SIZE STYLES
interface ISizeMap {
  massive: number
  huge: number
  big: number
  large: number
  medium: number
  small: number
  mini: number
}

const sizeMap: ISizeMap = {
  massive: 2.5,
  huge: 2.3,
  big: 2,
  large: 1.8,
  medium: 1.6,
  small: 1.4,
  mini: 1.2
}

export type InputSize = 'massive' | 'huge' | 'big' | 'large' | 'medium' | 'small' | 'mini'

const sizeStyles = (size: InputSize) => {
  return css`
    font-size: ${sizeMap[size]}rem;
  `
}

// oversize should only adjust the height
// do not add text sizing here
// it is oversizing the height while keeping standard text sizes
const oversizeStyles = css`
  line-height: 2.5em;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    line-height: 1.21428571em;
    padding-top: 1em;
    padding-bottom: 1em;
  }
`

// ICON STYLES
type IconPosition = 'left' | 'right'

const iconPositionStyles = (position: IconPosition) => {
  if (position === 'left') {
    return css`
      padding-left: 2.9em;
    `
  }
  if (position === 'right') {
    return css`
      padding-right: 2.9em;
    `
  }
}

const iconItemStyles = css`
  pointer-events: none;
  cursor: default;
  position: absolute;
  margin: 0;
  opacity: 0.35;
  display: flex;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 2.9em;
`
const iconWithPopulatedInputStyles = css`
  opacity: 1;
`

// PLACEHOLDER STYLES
const placeholderStyles = css`
  position: absolute;
  margin: 0;
  display: flex;
  top: 0;
  left: 16px;
  bottom: 0;
  align-items: center;
  justify-content: flex-start;
  width: 5.9em;
  z-index: 98;
  width: 100%;
`

// ICON STYLES
type LinkPosition = 'left' | 'right'

const linkItemStyles = css`
  position: absolute;
  margin: 0;
  display: flex;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 5.9em;
  z-index: 99;
`

const linkItemPositionStyles = (position: LinkPosition) => {
  if (position === 'left') {
    return css`
      left: 1px;
    `
  }
  if (position === 'right') {
    return css`
      right: 0;
    `
  }
}

const iconItemPositionStyles = (position: IconPosition) => {
  if (position === 'left') {
    return css`
      left: 1px;
    `
  }
  if (position === 'right') {
    return css`
      right: 0;
    `
  }
}

interface InputComponentProps {
  inputTestKey?: string
  value?: any
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  fluid?: boolean
  inputSize?: InputSize
  oversize?: boolean
  icon?: IconNames
  error?: boolean
  loading?: boolean
  iconPosition?: IconPosition
  autoFocus?: boolean
  wrapperCss?: EmotionStyles
  inputCss?: EmotionStyles
  iconCss?: EmotionStyles
  inputRef?: React.Ref<HTMLInputElement> | null
  linkText?: string
  linkPosition?: LinkPosition
  linkOnClick?: () => void
  placeholderText?: string
  placeholderSubtext?: string
}

type InputNativeProps = React.PropsWithoutRef<JSX.IntrinsicElements['input']>
export type InputProps = InputNativeProps & InputComponentProps

const Input: React.FC<InputProps> = ({
  value,
  onChange,
  fluid,
  inputSize = 'medium',
  oversize,
  icon,
  error,
  disabled,
  loading,
  iconPosition = 'right',
  autoFocus,
  wrapperCss,
  inputCss,
  iconCss,
  inputRef,
  linkText,
  linkPosition = 'right',
  linkOnClick,
  placeholderText,
  placeholderSubtext,
  ...rest
}: InputProps) => {
  const iconName = icon && !loading ? icon : loading ? 'spinner' : null

  return (
    <div
      className="inputUi"
      css={[
        inputWrapperStyles,
        fluid && fluidStyles,
        sizeStyles(inputSize),
        wrapperCss ? wrapperCss : null
      ]}
    >
      <input
        ref={inputRef}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        css={[
          // GENERAL STYLES
          inputFieldStyles,
          // SIZE STYLES
          sizeStyles(inputSize),
          oversize ? oversizeStyles : null,
          // ICON STYLES
          icon || loading ? iconPositionStyles(iconPosition) : null,
          // ERROR STYLES
          error ? inputErrorStyles : null,
          // DISABLED STYLES
          disabled ? disabledInputStyles : null,
          // CUSTOM USER STYLES
          inputCss ? inputCss : null
        ]}
        disabled={disabled}
        {...rest}
      />
      {iconName ? (
        <div
          className="inputIconWrapper"
          css={[
            iconItemPositionStyles(iconPosition),
            iconItemStyles,
            value ? iconWithPopulatedInputStyles : null,
            iconCss ? iconCss : null,
            disabled ? disabledIconStyles : null
          ]}
        >
          <Icon
            color="black"
            icon={iconName}
            css={css`
              font-size: 1.12em;
            `}
          />
        </div>
      ) : null}
      {linkText ? (
        <div
          className="inputLinkWrapper"
          css={[linkItemPositionStyles(linkPosition), linkItemStyles]}
        >
          <ImpressLink
            color="blue"
            css={css`
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.3px;

              color: #40454c;
            `}
            onClick={linkOnClick}
          >
            {linkText}
          </ImpressLink>
        </div>
      ) : null}
      {!value && placeholderText ? (
        <div className="inputPlaceholderWrapper" css={[placeholderStyles]}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <div
              css={css`
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.3px;

                color: #536065;
              `}
            >
              {placeholderText}
            </div>
            {placeholderSubtext ? (
              <div
                css={css`
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.3px;

                  color: ${GRAY_MEDIUM};
                `}
              >
                {placeholderSubtext}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  )
}

Input.displayName = 'Input'

export default Input

import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { css } from '@emotion/core'
import Router from 'next/router'
import Cookie from 'js-cookie'
import Button from '~/components/common/Button'

import { RAISED_SHADOW, RED_LIGHT } from '~/constants/theme.styles'
import { useMasqueradeLazyQuery, useMeQuery } from '~/generated/graphql'
import { useCookies } from '~/context/CookieContext'
import { Fuse } from '~/components/common/Fuse'
import Input from '~/components/common/Input'
import Icon from '~/components/common/Icon'

type FormData = {
  emailAddress: string
}

export const TopAdminBar = () => {
  const { masqueradeToken } = useCookies()
  const { data: meData } = useMeQuery({ fetchPolicy: 'cache-only' })
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const currentUser = meData?.me
  const isAdmin =
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length > 0 &&
    currentUser.roles.filter(e => e!.name == 'admin').length > 0

  const { register, handleSubmit } = useForm<FormData>()
  const emailAddressField = register('emailAddress')

  const [masquerade, { loading, data }] = useMasqueradeLazyQuery()

  const isMasquerading = masqueradeToken || (!loading && data && data.masquerade != null)

  useEffect(() => {
    if (!loading && data && data.masquerade != null) {
      Cookie.set('masqueradeToken', data.masquerade, {
        expires: new Date(new Date().setHours(new Date().getHours() + 1))
      })
      Router.reload()
    }
  }, [loading])

  const exitMasquerade = () => {
    Cookie.remove('masqueradeToken')
    Router.reload()
  }

  const onSubmit = handleSubmit(({ emailAddress }) => {
    if (emailAddress) {
      masquerade({ variables: { emailAddress } })
    }
  })

  return isAdmin || isMasquerading ? (
    <div
      css={css`
        position: fixed;
        top: ${isPanelOpen ? '0' : '-102px'};
        left: 0;
        right: 0;
        z-index: 99999;
        background: ${isMasquerading ? RED_LIGHT : '#ffffff'};
        box-shadow: ${isPanelOpen ? RAISED_SHADOW : 'none'};
        border-bottom: ${isPanelOpen && isMasquerading
          ? '1px solid #ff7b7b'
          : !isPanelOpen && isMasquerading
          ? '2px solid #ff7b7b'
          : isPanelOpen
          ? '1px solid #d6d6d6'
          : 'none'};
      `}
    >
      <div
        css={css`
          max-width: 430px;
          margin-left: auto;
          margin-right: auto;
          position: relative;
        `}
      >
        <div
          css={css`
            padding: 15px;
          `}
        >
          <div
            css={css`
              margin-bottom: 10px;
              text-align: center;
            `}
          >
            <strong>Viewing as:</strong> {meData?.me?.emailAddress}
            {isMasquerading ? (
              <Icon
                css={css`
                  margin-left: 2px;
                  position: absolute;
                  top: 10px;
                `}
                circular
                icon="close"
                size="large"
                element="button"
                color="red"
                onClick={() => {
                  exitMasquerade()
                }}
              />
            ) : null}
          </div>
          <div>
            {isAdmin || isMasquerading ? (
              <form noValidate autoComplete="off" onSubmit={onSubmit}>
                <Fuse bordered>
                  <Input
                    inputRef={emailAddressField.ref}
                    onChange={emailAddressField.onChange}
                    onBlur={emailAddressField.onBlur}
                    name={emailAddressField.name}
                    autoComplete="off"
                    iconPosition="left"
                    placeholder="Email address"
                  />
                  <Button
                    loading={loading}
                    css={css`
                      flex-grow: 0;
                    `}
                  >
                    View as
                  </Button>
                </Fuse>
              </form>
            ) : null}
          </div>
        </div>

        <Button
          onClick={() => {
            setIsPanelOpen(isOpen => !isOpen)
          }}
          type="button"
          unstyled
          css={css`
            position: fixed;
            right: 10px;
            left: auto;
            border-left: 1px solid ${isMasquerading ? '#ff7b7b' : '#d6d6d6'};
            border-right: 1px solid ${isMasquerading ? '#ff7b7b' : '#d6d6d6'};
            border-bottom: 1px solid ${isMasquerading ? '#ff7b7b' : '#d6d6d6'};
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            padding: 3px;
            display: block;
            background: ${isMasquerading ? RED_LIGHT : '#ffffff'};

            :hover,
            :active,
            :focus {
              background: ${isMasquerading ? RED_LIGHT : '#ffffff'};
              border-left: 1px solid ${isMasquerading ? 'red' : '#d6d6d6'};
              border-right: 1px solid ${isMasquerading ? 'red' : '#d6d6d6'};
              border-bottom: 1px solid ${isMasquerading ? 'red' : '#d6d6d6'};

              .span {
                background: ${isMasquerading ? 'red' : '#b2b2b2'};
              }
            }
            .span {
              width: 40px;
              height: 3px;
              background: ${isMasquerading ? '#ff7b7b' : '#d6d6d6'};
              margin-top: 2px;
              margin-bottom: 2px;
              display: block;
              border-radius: 2px;
            }
          `}
        >
          <span className="span" />
          <span className="span" />
        </Button>
      </div>
    </div>
  ) : null
}

import { slugify, slugifyCity } from '../components/domain/browse/browseUtils'

const Links = {
  // STATIC PAGE LINKS
  home: (): string => `/`,
  about: (): string => `/about`,
  contact: (): string => `/contact-us`,
  faq: (): string => `/faq`,
  terms: (): string => `/terms`,
  privacy: (): string => `/privacy`,
  postAJob: (): string => `/post-a-job`,
  login: (): string => `/login`,
  signup: (): string => `/signup`,
  signout: (): string => `/signout`,
  search: (): string => `/search`,
  blog: (): string => `/career-advice`,
  press: (): string => `/press`,
  workAtJoblist: (): string => `/work-at-joblist`,

  // EXTERNAL LINKS
  twitter: (): string => 'https://twitter.com/joblist_com',
  facebook: (): string => 'https://www.facebook.com/joblistcom',
  linkedin: (): string => 'https://www.linkedin.com/company/joblist-com',
  instagram: (): string => 'https://www.instagram.com/joblist_com',
  wilburLabs: (): string => 'https://www.wilburlabs.com',
  clearbit: (): string => 'https://clearbit.com'

  // TODO: BLOG LINKS
  // TODO: SEARCH LINKS
}

// auth routes
export const loginUrl = '/login'
export const signupUrl = '/signup'
export const signoutUrl = '/signout'
export const forgotPasswordUrl = '/forgot-password'

// profile routes
export const profileUrl = '/profile'

// job collection routes
export const savedJobsUrl = '/saved-jobs'
export const myJoblistsUrl = '/my-joblists'
export const shareJobListUrl = '/share/[shareId]'
export const joblistUrl = '/joblist/[joblistId]'

// job alert routes
export const jobAlertsUrl = '/job-alerts'

// job posting routes
export const superPostUrl = '/post'
export const postJobLandingUrl = '/post-now'
export const postJobFreeTrialLandingUrl = '/post-free'
export const postJobFreeTrialFaqUrl = '/post-free#faq-1'
export const postJobUrl = '/post-a-job'
export const myPostingsUrl = '/my-postings'
export const jobPostingDetailsUrl = '/create/job-details'
export const jobPostingDeliveryUrl = '/create/job-delivery'
export const jobPostingCheckoutUrl = '/create/job-checkout'
export const jobPostingSuccessUrl = '/job-success'
export const jobPostingReceiptUrl = (jobId: string, receiptId: string) =>
  `/job/${jobId}/receipt/${receiptId}`
export const jobPostingRenewUrl = (jobId: string) => `/job/${jobId}/renew`
export const jobPageUrl = (jobId: string) => `/job/${jobId}`

// browse routes
export const browsePage = (): string => '/b/united-states-job-market'
export const browseUKPage = (): string => '/uk/b/united-kingdom-job-market'
export const browseAllJobs = (): string => '/b/all-jobs'
export const browseAllJobMarkets = (): string => '/b/all-job-markets'
export const browseState = (state: string) => `/b/${slugify(state)}`
export const browseIndustry = (industry: string) => `/b/${slugify(industry)}-jobs-hiring`
export const browseCity = (stateCode: string, cityName: string) =>
  `/l/${slugifyCity(cityName)}-${stateCode}`.toLowerCase()

export const browseLocationJobTitle = (
  stateCode: string,
  cityName: string,
  jobTitle: string
): string => `/j/${slugify(jobTitle)}-jobs/${slugifyCity(cityName)}-${stateCode}`.toLowerCase()

export const browseLocationIndustry = (
  stateName: string,
  cityName: string,
  industry: string
): string => `/b/${slugify(stateName)}/${slugifyCity(cityName)}/${slugify(industry)}-jobs-hiring`

export const browseJobTitle = (role: string): string => `/b/${slugify(role)}-jobs`

export const browseStateJobTitle = (stateName: string, jobTitle: string): string =>
  `/b/${slugify(stateName)}/${slugify(jobTitle)}-jobs`

export const browseStateIndustry = (stateName: string, industry: string): string =>
  `/b/${slugify(stateName)}/${slugify(industry)}-jobs-hiring`

// intl browse routes
export const intlBrowsePage = (): string => '/uk/b/united-kingdom-job-market'
export const intlBrowseAllJobs = (country: string): string => `/${country}/b/all-jobs`
export const intlBrowseAllJobMarkets = (country: string): string => `/${country}/b/all-job-markets`
export const intlBrowseState = (country: string, state: string) => `/${country}/b/${slugify(state)}`
export const intlBrowseIndustry = (country: string, industry: string) =>
  `/${country}/b/${slugify(industry)}-jobs-hiring`
export const intlBrowseCity = (country: string, stateCode: string, cityName: string) => {
  if (country.toLowerCase() == 'uk') {
    return `/${country}/l/${slugifyCity(cityName)}-uk`.toLowerCase()
  } else {
    return `/${country}/l/${slugifyCity(cityName)}-${stateCode}`.toLowerCase()
  }
}

export const intlBrowseLocationJobTitle = (
  country: string,
  stateCode: string,
  cityName: string,
  jobTitle: string
): string => {
  if (country.toLowerCase() == 'uk') {
    return `/${country}/j/${slugify(jobTitle)}-jobs/${slugifyCity(cityName)}-uk`.toLowerCase()
  } else {
    return `/${country}/j/${slugify(jobTitle)}-jobs/${slugifyCity(
      cityName
    )}-${stateCode}`.toLowerCase()
  }
}

export const intlBrowseLocationIndustry = (
  country: string,
  stateName: string,
  cityName: string,
  industry: string
): string =>
  `/${country}/b/${slugify(stateName)}/${slugifyCity(cityName)}/${slugify(industry)}-jobs-hiring`

export const intlBrowseJobTitle = (country: string, role: string): string =>
  `/${country}/b/${slugify(role)}-jobs`

export const intlBrowseStateJobTitle = (
  country: string,
  stateName: string,
  jobTitle: string
): string => `/${country}/b/${slugify(stateName)}/${slugify(jobTitle)}-jobs`

export const intlBrowseStateIndustry = (
  country: string,
  stateName: string,
  industry: string
): string => `/${country}/b/${slugify(stateName)}/${slugify(industry)}-jobs-hiring`

// hreflang
const langs = {
  us: 'en-us',
  uk: 'en-gb'
}
export const browseIndustryHrefLang = (industry: string) =>
  Object.entries(langs).map(([country, lang]) => {
    return {
      lang,
      url:
        country.toLowerCase() == 'us'
          ? browseIndustry(industry)
          : intlBrowseIndustry(country, industry)
    }
  })
export const browseJobTitleHrefLang = (role: string) =>
  Object.entries(langs).map(([country, lang]) => {
    return {
      lang,
      url: country.toLowerCase() == 'us' ? browseJobTitle(role) : intlBrowseJobTitle(country, role)
    }
  })

export default Links

import { css } from '@emotion/core'
import { FuseItemType } from './Fuse'
import { screenSizeType, mqSizeMap, GRAY_LIGHT, PURPLE } from '../../../constants/theme.styles'

// ATTACHED INPUT STYLES
export const inputWrapperStyles = css`
  flex-grow: 3;
`

export const inputStyles = css`
  width: 100%;
`

export const inputBorderedStyles = css`
  border: 2px solid #000;
`

export const inputFocusStyles = (focusOutline: boolean, bordered: boolean) => {
  const focusBorderColor = focusOutline ? PURPLE : GRAY_LIGHT
  const borderedFocusColor = bordered ? '#000000' : focusBorderColor
  return css`
    :focus {
      border-color: ${borderedFocusColor};
    }
  `
}

export const inputPositionStyles = (
  position: FuseItemType,
  focusOutline: boolean,
  bordered: boolean
) => {
  const focusedBoxShadow = bordered ? 'none' : `-1px 0 0 0 ${focusOutline ? PURPLE : 'transparent'}`
  if (position === 'attached' || position == 'last') {
    return css`
      border-left: none;
      :focus {
        border-left: none;
        box-shadow: ${focusedBoxShadow};
      }
    `
  }
}

export const inputStacksAtStyles = (
  position: FuseItemType,
  screenSize: screenSizeType,
  focusOutline: boolean,
  bordered: boolean
) => {
  const isAttachedOrLast = position === 'attached' || position == 'last'

  if (bordered) {
    return css`
      @media ${mqSizeMap[screenSize]} {
        ${isAttachedOrLast ? `border: 2px solid #000000` : ''};
        ${isAttachedOrLast ? 'border-top: none' : ''};
        box-shadow: none;
        :focus {
          ${isAttachedOrLast ? 'border-top: none' : ''};
          border-left: 2px solid #000000;
          box-shadow: none;
        }
      }
    `
  }

  return css`
    @media ${mqSizeMap[screenSize]} {
      ${isAttachedOrLast ? `border: 1px solid ${GRAY_LIGHT}` : ''};
      ${isAttachedOrLast ? 'border-top: none' : ''};
      box-shadow: none;
      :focus {
        ${isAttachedOrLast ? 'border-top: none' : ''};
        ${isAttachedOrLast
          ? `box-shadow: 0 -1px 0 0 ${focusOutline ? PURPLE : 'transparent'}`
          : ''};
        border-left: 1px solid ${focusOutline ? PURPLE : GRAY_LIGHT};
      }
    }
  `
}

// LOCATION INPUT STYLES
export const locationInputWrapperStyles = css`
  display: flex;
`

export const listWrapperStyles = (radius: string, bordered: boolean, focusOutline: boolean) => {
  return css`
    border-top: none;
    ${focusOutline && !bordered ? `border-color: ${PURPLE}` : ''};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -${radius};
    left: ${bordered ? '0' : '-1px'};
    right: ${bordered ? '2px' : '0'};
  `
}

export const listWrapperStacksAtStyles = (
  screenSize: screenSizeType,
  bordered: boolean,
  focusOutline: boolean
) => {
  if (bordered) {
    return css`
      @media ${mqSizeMap[screenSize]} {
        border-left: 2px solid #000;
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        right: 0;
      }
    `
  }

  return css`
    @media ${mqSizeMap[screenSize]} {
      left: 0;
      ${focusOutline ? `border-color: ${PURPLE}` : ''};
    }
  `
}

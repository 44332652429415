import { industryType } from '../../../graphql/browse/browseJobsQuery.graphql'
import {
  browseState,
  browseIndustry,
  browseCity,
  browseLocationJobTitle,
  browseLocationIndustry,
  browseStateIndustry,
  browseStateJobTitle,
  browseJobTitle,
  intlBrowseJobTitle,
  intlBrowseState,
  intlBrowseIndustry,
  intlBrowseCity,
  intlBrowseLocationJobTitle,
  intlBrowseLocationIndustry,
  intlBrowseStateIndustry,
  intlBrowseStateJobTitle
} from '../../../lib/links'

export type CityLinkData = { city: string; stateCode: string }
export type StateLinkData = { state: string; stateCode: string }
export type RoleLinkData = { role: string }
export type LocationIndustryLinkData = {
  cityName: string
  stateName: string
  stateCode: string
  industry: string
}
export type StateIndustryLinkData = { stateName: string; stateCode: string; industry: string }
export type LocationJobTitleLinkData = {
  cityName: string
  stateName: string
  stateCode: string
  jobTitle: string
}
export type StateJobTitleLinkData = { stateName: string; stateCode: string; jobTitle: string }

export const displayCity = (cityName: string, stateCode: string) => {
  return `${cityName}, ${stateCode}`
}

export function slugifyCity(str: string) {
  const slug = slugify(str.replace(/\./g, ''))
  return slug
}

export function slugify(str: string) {
  const slug = str
    // .replace(/-/g, '_') // Replace - with _ (hyphenated words like non-profit become non_profit)
    .replace(/C#/gi, 'C-Sharp') // edge case replace C# with C-sharp
    .replace(/, /g, '~') // Replace ,space with ~
    .replace(/\//g, '__') // Replace / with __
    // .replace(/\+/g, '~plus~') // Replace + with ~plus~
    .replace(/ & /g, '-and-') // Replace space&space with -and-
    .replace(/&/g, '~and~') // Replace & with and
    .replace(/\s/g, '-') // Replace spaces with -
  return encodeURIComponent(slug)
}

export function deSlugify(str: string) {
  const decodded = decodeURIComponent(str)
  return decodded
    .replace(/C-Sharp/gi, 'C#') // revert C#
    .replace(/-dot-/g, '.') // revert .
    .replace(/~plus~/g, '+') // revert +
    .replace(/~and~/g, '&') // revert "&""
    .replace(/-and-/g, ' & ') // revert " & "
    .replace(/\-/g, ' ') // revert space
    .replace(/\__/g, '/') // revert /
    .replace(/\_/g, '-') // revert hyphenated words
    .replace(/\~/g, ', ') // revert comma
}

export const retiredJobTitles: Record<string, string> = {
  'Childcare Worker': 'Child Care Worker',
  'Electromechanical Technician': 'Electro Mechanical Technician',
  'Fire Fighter': 'Firefighter',
  'Helpdesk Manager': 'Help Desk Manager',
  'Sales Person': 'Salesperson',
  Zookeeper: 'Zoo Keeper'
}

export const browseLinks = {
  jobTitles: (roles: RoleLinkData[]) => {
    return roles.map(role => ({
      text: role.role,
      href: browseJobTitle(role.role)
    }))
  },
  states: (states: StateLinkData[]) => {
    return states.map(state => ({
      text: state.state,
      href: browseState(state.state)
    }))
  },
  industries: (industries: industryType[]) => {
    return industries.map(industry => ({
      text: industry,
      href: browseIndustry(industry)
    }))
  },
  cities: (cities: CityLinkData[]) => {
    return cities.map(({ city, stateCode }) => ({
      text: displayCity(city, stateCode),
      href: browseCity(stateCode, city)
    }))
  },
  locationJobTitle: (
    data: LocationJobTitleLinkData[],
    options: { textKey: 'jobTitle' | 'cityName' | 'stateName' }
  ) => {
    return data.map(item => ({
      text:
        options.textKey === 'cityName'
          ? displayCity(item.cityName, item.stateCode)
          : item[options.textKey],
      href: browseLocationJobTitle(item.stateCode, item.cityName, item.jobTitle)
    }))
  },
  locationIndustry: (
    data: LocationIndustryLinkData[],
    options: { textKey: 'industry' | 'cityName' | 'stateName' }
  ) => {
    return data.map(item => ({
      text:
        options.textKey === 'cityName'
          ? displayCity(item.cityName, item.stateCode)
          : item[options.textKey],
      href: browseLocationIndustry(item.stateName, item.cityName, item.industry)
    }))
  },
  stateIndustry: (
    data: StateIndustryLinkData[],
    options: { textKey: 'industry' | 'stateName' | 'stateCode' }
  ) => {
    return data.map(item => ({
      text: item[options.textKey],
      href: browseStateIndustry(item.stateName, item.industry)
    }))
  },
  stateJobTitle: (
    data: StateJobTitleLinkData[],
    options: { textKey: 'jobTitle' | 'stateName' | 'stateCode' }
  ) => {
    return data.map(item => ({
      text: item[options.textKey],
      href: browseStateJobTitle(item.stateName, item.jobTitle)
    }))
  }
}

export const intlBrowseLinks = {
  jobTitles: (roles: RoleLinkData[], country: string) => {
    return roles.map(role => ({
      text: role.role,
      href: intlBrowseJobTitle(country, role.role)
    }))
  },
  states: (states: StateLinkData[], country: string) => {
    return states.map(state => ({
      text: state.state,
      href: intlBrowseState(country, state.state)
    }))
  },
  industries: (industries: industryType[], country: string) => {
    return industries.map(industry => ({
      text: industry,
      href: intlBrowseIndustry(country, industry)
    }))
  },
  cities: (cities: CityLinkData[], country: string) => {
    return cities.map(({ city, stateCode }) => ({
      text: displayCity(city, stateCode),
      href: intlBrowseCity(country, stateCode, city)
    }))
  },
  locationJobTitle: (
    data: LocationJobTitleLinkData[],
    options: { textKey: 'jobTitle' | 'cityName' | 'stateName' },
    country: string
  ) => {
    return data.map(item => ({
      text:
        options.textKey === 'cityName'
          ? displayCity(item.cityName, item.stateCode)
          : item[options.textKey],
      href: intlBrowseLocationJobTitle(country, item.stateCode, item.cityName, item.jobTitle)
    }))
  },
  locationIndustry: (
    data: LocationIndustryLinkData[],
    options: { textKey: 'industry' | 'cityName' | 'stateName' },
    country: string
  ) => {
    return data.map(item => ({
      text:
        options.textKey === 'cityName'
          ? displayCity(item.cityName, item.stateCode)
          : item[options.textKey],
      href: intlBrowseLocationIndustry(country, item.stateName, item.cityName, item.industry)
    }))
  },
  stateIndustry: (
    data: StateIndustryLinkData[],
    options: { textKey: 'industry' | 'stateName' | 'stateCode' },
    country: string
  ) => {
    return data.map(item => ({
      text: item[options.textKey],
      href: intlBrowseStateIndustry(country, item.stateName, item.industry)
    }))
  },
  stateJobTitle: (
    data: StateJobTitleLinkData[],
    options: { textKey: 'jobTitle' | 'stateName' | 'stateCode' },
    country: string
  ) => {
    return data.map(item => ({
      text: item[options.textKey],
      href: intlBrowseStateJobTitle(country, item.stateName, item.jobTitle)
    }))
  }
}

const singularWordList = [
  'delivery',
  'travel',
  'insurance',
  'security',
  'java',
  'farm',
  'pacu rn',
  'hvac',
  'lvn',
  'claims',
  'landman',
  'dental',
  'payroll',
  'help desk',
  'dpa',
  'sports',
  'laundry',
  'infantry',
  'valet',
  'journalism',
  'crna',
  'chef de partie',
  'oracle dba',
  'post office',
  'assistant director of nursing',
  'assistant director Of admissions',
  'veteranary',
  'inventory',
  'data entry',
  'actuary'
]

export const canPluralize = (str: string): boolean => {
  const matchWordList = singularWordList.includes(str.toLowerCase())
  const matchRegex = /^director of|^vice president of|^vp of|staff$|support$|sales$|marketing$|engineering$|service$/gi.test(
    str
  )
  return !matchWordList && !matchRegex
}

import { css } from '@emotion/core'
import { FuseItemType } from './Fuse'
import { screenSizeType, mqSizeMap } from '../../../constants/theme.styles'

export const generalBorderRadiusStyles = (position: FuseItemType, radius: string | number) => {
  if (position === 'first') {
    return css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: ${radius};
      border-bottom-left-radius: ${radius};
    `
  }
  if (position === 'attached') {
    return css`
      border-radius: 0;
    `
  }
  if (position === 'last') {
    return css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: ${radius};
      border-bottom-right-radius: ${radius};
    `
  }
}

export const generalStacksAtContainerStyles = (screenSize: screenSizeType) => {
  return css`
    @media ${mqSizeMap[screenSize]} {
      flex-wrap: wrap;
      flex-direction: column;
    }
  `
}

export const generalStacksAtBorderRadiusStyles = (
  position: FuseItemType,
  screenSize: screenSizeType,
  radius: string | number
) => {
  return css`
    @media ${mqSizeMap[screenSize]} {
      ${generalBorderStyles(position, radius)}
    }
  `
}

const generalBorderStyles = (position: FuseItemType, radius: string | number) => {
  if (position === 'first') {
    return css`
      border-top-right-radius: ${radius};
      border-top-left-radius: ${radius};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `
  }
  if (position === 'attached') {
    return css`
      border-radius: 0;
    `
  }
  if (position === 'last') {
    return css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: ${radius};
      border-bottom-right-radius: ${radius};
    `
  }
}

export const generalRaisedStyles = css`
  box-shadow: 0 1.33px 3.21px 0 rgba(165, 174, 202, 0.06),
    0 2.67px 3.69px 0 rgba(165, 174, 202, 0.08), 0 4px 4px 0 rgba(165, 174, 202, 0.04);
`

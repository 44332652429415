import React from 'react'
import { css } from '@emotion/core'
import { screenSizeType } from '../../../constants/theme.styles'
import {
  buttonStyles,
  buttonPositionStyles,
  buttonStacksAtStyles,
  buttonStacksAtPositionStyles
} from './buttonStyles'
import {
  generalBorderRadiusStyles,
  generalStacksAtBorderRadiusStyles,
  generalStacksAtContainerStyles,
  generalRaisedStyles
} from './generalStyles'
import {
  inputPositionStyles,
  inputStacksAtStyles,
  locationInputWrapperStyles,
  inputWrapperStyles,
  inputStyles,
  inputFocusStyles,
  inputBorderedStyles,
  listWrapperStyles,
  listWrapperStacksAtStyles
} from './inputStyles'

export type FuseItemType = 'first' | 'last' | 'attached'

const attachContainerStyles = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
`

interface FuseProps {
  stacksAt?: screenSizeType
  radius?: string | number
  focusOutline?: boolean
  bordered?: boolean
  raised?: boolean
}

// START FUSE ITEM
type SupportedComponentTypes =
  | 'Input'
  | 'LocationInputUi'
  | 'Button'
  | 'Autocomplete'
  | 'Fuse'
  | 'FuseItem'
interface FuseItemProps {
  componentType: SupportedComponentTypes
  isValidFuseItem?: boolean
  children(props: any): JSX.Element
}

export const FuseItem: React.FC<FuseItemProps> = ({ children, ...fuseData }) => {
  return children(fuseData)
}

FuseItem.displayName = 'FuseItem'

// END FUSE ITEM

type GetChildrenProps = {
  children?: React.ReactNode
  stacksAt?: screenSizeType
  radius?: string
  focusOutline?: boolean
  bordered?: boolean
  raised?: boolean
}

const getChildren: any = ({
  children,
  stacksAt,
  radius = '0.4rem',
  focusOutline = true,
  bordered = false,
  raised = false
}: GetChildrenProps) => {
  const nonEmptyChildren = React.Children.toArray(children).filter(o => o)
  const validFuseItems = nonEmptyChildren.filter(child => {
    if (React.isValidElement(child)) {
      if (child.props.isValidFuseItem !== false) {
        return child
      }
    }
  })

  const childCount = validFuseItems.length
  return React.Children.map(nonEmptyChildren, (child, index) => {
    const attachPosition = index === 0 ? 'first' : index === childCount - 1 ? 'last' : 'attached'

    if (React.isValidElement(child)) {
      const component = child.type as React.FC
      const componentName = (component.displayName === 'EmotionCssPropInternal'
        ? child.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName
        : component.displayName) as SupportedComponentTypes

      const componentType = componentName === 'FuseItem' ? child.props.componentType : componentName

      if (componentType === 'Fuse') {
        return child
      }

      let attachProps = {}
      if (componentType === 'Button') {
        attachProps = {
          loadingCss: [
            generalBorderRadiusStyles(attachPosition, radius),
            stacksAt ? generalStacksAtBorderRadiusStyles(attachPosition, stacksAt, radius) : null,
            child.props.loadingCss
          ],
          extraCss: [
            generalBorderRadiusStyles(attachPosition, radius),
            stacksAt ? generalStacksAtBorderRadiusStyles(attachPosition, stacksAt, radius) : null,
            buttonStyles,
            buttonPositionStyles(attachPosition),
            stacksAt ? buttonStacksAtStyles(stacksAt) : null,
            stacksAt ? buttonStacksAtPositionStyles(attachPosition, stacksAt) : null
          ]
        }
      }

      if (componentType === 'Input') {
        attachProps = {
          wrapperCss: [inputWrapperStyles, child.props.wrapperCss],
          inputCss: [
            inputStyles,
            bordered ? inputBorderedStyles : null,
            inputFocusStyles(focusOutline, bordered),
            inputPositionStyles(attachPosition, focusOutline, bordered),
            stacksAt ? inputStacksAtStyles(attachPosition, stacksAt, focusOutline, bordered) : null,
            generalBorderRadiusStyles(attachPosition, radius),
            stacksAt ? generalStacksAtBorderRadiusStyles(attachPosition, stacksAt, radius) : null
          ]
        }
      }

      if (componentType === 'LocationInputUi') {
        attachProps = {
          wrapperCss: [inputWrapperStyles, child.props.wrapperCss, locationInputWrapperStyles],
          attachInputProps: {
            wrapperCss: [inputWrapperStyles, child.props.wrapperCss],
            inputCss: [
              inputStyles,
              bordered ? inputBorderedStyles : null,
              inputFocusStyles(focusOutline, bordered),
              inputPositionStyles(attachPosition, focusOutline, bordered),
              stacksAt
                ? inputStacksAtStyles(attachPosition, stacksAt, focusOutline, bordered)
                : null,
              generalBorderRadiusStyles(attachPosition, radius),
              stacksAt ? generalStacksAtBorderRadiusStyles(attachPosition, stacksAt, radius) : null,
              child.props.inputElement ? child.props.inputElement.props.inputCss : null
            ]
          },
          topLineCss: css`
            display: block;
          `,
          listWrapperCss: [
            listWrapperStyles(radius, bordered, focusOutline),
            stacksAt ? listWrapperStacksAtStyles(stacksAt, bordered, focusOutline) : null,
            child.props.listWrapperCss
          ]
        }
      }

      if (componentType === 'Autocomplete' || componentType === 'KeywordSuggestionInput') {
        attachProps = {
          containerCss: [inputWrapperStyles, child.props.wrapperCss],
          attachInputProps: {
            ...child.props?.attachInputProps,
            wrapperCss: [
              css`
                width: 100%;
              `
            ],
            inputCss: [
              inputStyles,
              bordered ? inputBorderedStyles : null,
              inputFocusStyles(focusOutline, bordered),
              inputPositionStyles(attachPosition, focusOutline, bordered),
              stacksAt
                ? inputStacksAtStyles(attachPosition, stacksAt, focusOutline, bordered)
                : null,
              generalBorderRadiusStyles(attachPosition, radius),
              stacksAt ? generalStacksAtBorderRadiusStyles(attachPosition, stacksAt, radius) : null
            ]
          }
        }
      }

      if (child.props.children && componentName !== 'FuseItem') {
        const result = React.cloneElement(child as React.ReactElement<any>, {
          ...attachProps,
          children: getChildren({
            children: child.props.children,
            stacksAt,
            radius,
            focusOutline,
            bordered,
            raised
          })
        })

        return result
      } else {
        return React.cloneElement(child as React.ReactElement<any>, attachProps)
      }
    } else {
      return child
    }
  })
}

const Fuse: React.FC<FuseProps> = ({
  children,
  stacksAt,
  radius = '0.4rem',
  focusOutline = true,
  bordered = false,
  raised = false,
  ...rest
}) => {
  const childrenWithFuseProps = getChildren({
    children,
    stacksAt,
    radius,
    focusOutline,
    bordered,
    raised
  })

  return (
    <div
      css={[
        attachContainerStyles,
        stacksAt ? generalStacksAtContainerStyles(stacksAt) : null,
        raised ? generalRaisedStyles : null
      ]}
      {...rest}
    >
      {childrenWithFuseProps}
    </div>
  )
}

export default Fuse

import React from 'react'
import { css } from '@emotion/core'
import { GRAY_MEDIUM_DARK, BLACK } from '~/constants/theme.styles'

export type ImpressLinkColors = 'purple' | 'blue' | 'gray'

const impressLinkStyles = css`
  cursor: pointer;
  text-decoration: none;
  color: #000;
  :hover {
    text-decoration: none;
  }
`

const colorStyles = (color: ImpressLinkColors) => {
  if (color === 'purple') {
    return css`
      border-bottom: 2px solid #bfa7ff;
      box-shadow: 0px -0.4em 0px #eee8ff inset;
      :hover {
        color: #966dfd;
        border-color: #bfa7ff;
      }
    `
  }
  if (color === 'blue') {
    return css`
      border-bottom: 2px solid #44cbfc;
      box-shadow: 0px -0.4em 0px #d5f4ff inset;
      :hover {
        color: #008bbd;
      }
    `
  }
  if (color === 'gray') {
    return css`
      color: ${GRAY_MEDIUM_DARK};
      border-bottom: 2px solid ${GRAY_MEDIUM_DARK};
      box-shadow: 0px -0.4em 0px #d8d8d8 inset;
      :hover {
        color: ${BLACK};
        border-color: ${BLACK};
      }
    `
  }
}

const boldStyles = css`
  font-weight: 600;
`

const hideUnderlineStyes = css`
  border-bottom: none;
`
const hideHighlightStyes = css`
  box-shadow: none;
`

export type ImpressLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  /** Use for a links that open a different site */
  external?: boolean
  /** Makes the link color the same as the current text color and adds an underline */
  color?: ImpressLinkColors
  // underline?: UnderlineOptions
  bold?: boolean
  showHighlight?: boolean
  showUnderline?: boolean
  children: React.ReactNode
}

const ImpressLink = React.forwardRef(
  (
    {
      color = 'purple',
      showUnderline = true,
      showHighlight = true,
      bold = true,
      external,
      children,
      ...rest
    }: ImpressLinkProps,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const target = external ? '_blank' : undefined
    const rel = external ? 'noopener noreferrer' : undefined
    return (
      <a
        ref={ref}
        css={[
          impressLinkStyles,
          colorStyles(color),
          bold ? boldStyles : null,
          !showUnderline ? hideUnderlineStyes : null,
          !showHighlight ? hideHighlightStyes : null
        ]}
        target={target}
        rel={rel}
        {...rest}
      >
        {children}
      </a>
    )
  }
)

export default ImpressLink
